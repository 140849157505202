import React from 'react'
import { Link } from 'gatsby'

import worker_illustration from '../images/ud_co_workers.svg'

export default function SectionContact({ contact }) {
  const { title } = contact
  const { call_to_action } = contact
  return (
    <section id="contact-section" className="my-10 md:my-32 lg:my-48 relative">
      <div className="container mx-auto px-2 xsm:px-4 lg:px-8 md:flex md:flex-row-reverse md:items-center">
        <div className="md:w-1/2 px-4 sm:px-20 md:px-10">
          <img
            className="w-full h-auto"
            src={worker_illustration}
            alt="Two co-workers sitting near a large window working on a single computer"
          />
        </div>
        <div className="md:w-1/2 sm:mt-6 md:mt-0 md:px-10">
          <h2 className="text-3xl lg:text-5xl xxl:text-6xl text-center md:text-left text-indigo-darkest font-black">
            {title}
          </h2>
          <div className="my-8 xxl:my-24 flex items-center justify-center md:justify-start">
            <Link
              to="/contact"
              className="text-lg font-semibold no-underline hover:bg-indigo-darkest md:mt-6 p-3 xsm:p-4 text-indigo-darkest hover:text-indigo-lightest border-2 border-indigo-darkest transition-bg"
            >
              {call_to_action}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
